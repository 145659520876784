import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import BaseComponent from "./BaseComponent";
import { Grid, IconButton, CardContent, Card, CircularProgress, CardHeader, Avatar, CardMedia, CardActions, Fade } from "@material-ui/core";
import Frame from "./Frame";

import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: "100hv",
    },
    content: {
      paddingTop: theme.spacing(2),
    },

    logo: {
      height: 0,
      paddingTop: "22.6%", // 16:9,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
    },
    avatar: {
      backgroundColor: red[500],
    },
    sozialButtons: {
      justifyContent: "center",
    },
    sozialIcons: {
      "& svg": {
        fontSize: 50,
      },
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {}

interface IStates {
  loading: boolean;
  showImage: boolean;
  imageIdx: number;
}

class RPG extends BaseComponent<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      showImage: true,
      imageIdx: 1,
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({ showImage: !this.state.showImage });
    }, 5000);
  }

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    if (loading) {
      return (
        <React.Fragment>
          <CircularProgress />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/impressum">
            <Helmet>
              <title>Rayne p. Grocks | Impressum</title>
              <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Frame>
              <Grid item xs={12}>
                <Grid container spacing={2} className={classes.root}>
                  <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body2" component="p">
                          Rayne p. Grocks (Eingetragener Künstlername)
                          <br />
                          <br />
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Clara-Zetkin-Str 179
                          <br />
                          14547 Beelitz, Germany
                          <br />
                          Verantwortlich für den Inhalt: Rayne p. Grocks
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                          <br />
                          <br />
                          Haftungsausschluss Inhalt des Onlineangebotes <br />
                          Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen.
                          Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung
                          der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich
                          ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind
                          freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte
                          Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen. Sofern Teile oder
                          einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die
                          übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                          <br />
                          Verweise und Links
                          <br />
                          Bei direkten oder indirekten Verweisen auf fremde Webseiten (Hyperlinks), die außerhalb des Verantwortungsbereiches des Autors liegen,
                          würde eine Haftungs-verpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm
                          technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit ausdrücklich,
                          dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige
                          Ge-staltung, die Inhalte oder die Urheberschaft der verlinkten /verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert
                          er sich hiermit ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese
                          Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor
                          eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf deren
                          Inhalt externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus
                          der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen
                          wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          <br />
                          <br />
                          Urheber- und Kennzeichenrecht <br />
                          Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu
                          beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente,
                          Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und
                          Warenz-eichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen
                          eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte
                          Dritter geschützt sind! Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine
                          Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten
                          Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          <br />
                          <br />
                          Datenschutz
                          <br />
                          Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen,
                          Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme
                          und Bezahlung aller angebotenen Dienste ist – soweit technisch möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe
                          anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben
                          veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht
                          ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei
                          Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten. Diese Website benutzt Google Analytics, einen Webanalysedienst der Google
                          Inc. (“Google”) Google Analytics verwendet sog. “Cookies”, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der
                          Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre Benutzung diese Website (einschließlich
                          Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um
                          Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit
                          der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls
                          an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird
                          in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie können die Installation der Cookies durch eine
                          entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
                          nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der
                          Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck
                          einverstanden.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Frame>
          </Route>

          <Route exact path="*">
            <Helmet>
              <title>Rayne p. Grocks</title>
              <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Grid item xs={12}>
              <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                  <Frame>
                    <Grid className={classes.content} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        <Card className={classes.root}>
                          <CardHeader
                            avatar={<Avatar alt="Rayne p. Grocks" src="/avatar.jpg" />}
                            action={<></>}
                            title="Rayne p. Grocks"
                            subheader="Fotograf und Musiker"
                          />
                          <CardMedia className={classes.logo} image="/logo.png" title="logo" />
                          <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Ich nehme Dich mit, zu den Orten in der Umgebung und in die Ferne. Landschaftsfotografie ist ein Abenteuer, genaue Planung und Liebe
                              zur Natur. Tauche mit mir ein, um die Schönheit unserer Welt zu sehen und für die Ewigkeit festzuhalten. Denn jedes Wohnzimmer braucht
                              ein Fenster zum Träumen.
                            </Typography>
                            <br />
                            <Typography variant="body2" component="p">
                              Webseite noch In Arbeit
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} className={classes.content}>
                        <Fade
                          in={this.state.showImage}
                          onExited={() => {
                            let i = this.state.imageIdx;
                            i++;
                            if (i > 3) {
                              i = 1;
                            }
                            this.setState({ imageIdx: i, showImage: true });
                          }}
                        >
                          <Card>
                            <CardMedia className={classes.media} image={"/img" + this.state.imageIdx + ".jpg"} title="logo" />
                          </Card>
                        </Fade>
                      </Grid>

                      <Grid item xs={12}>
                        <Card className={classes.root}>
                          <CardActions className={classes.sozialButtons}>
                            <IconButton className={classes.sozialIcons} size="medium" aria-label="instagramm" href="https://www.instagram.com/raynepgrocks/">
                              <InstagramIcon />
                            </IconButton>
                            <IconButton className={classes.sozialIcons} aria-label="YouTube" href="https://www.youtube.com/channel/UCZfwfpufBJ_Q6yJbDzX8fVA">
                              <YouTubeIcon />
                            </IconButton>
                            <IconButton className={classes.sozialIcons} aria-label="facebook" href="https://www.facebook.com/rayne.p.grocks">
                              <FacebookIcon />
                            </IconButton>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>
                  </Frame>
                </Grid>
              </Grid>
            </Grid>
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withRouter(RPG));
