import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ListItemIcon, List, ListItemText, ListSubheader } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import BaseComponent from "./BaseComponent";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    link: {
      color: "#aaaaaa",

      "&:hover": {
        color: "#ffffff",
      },
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {}

interface IStates {}

class Menu extends BaseComponent<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <hr />
          <List subheader={<ListSubheader>Allgemein</ListSubheader>}>
            <ListItem component={Link} to="/">
              <ListItemText className={classes.link} primary={"Home"} />
            </ListItem>
            <ListItem component={Link} to="/impressum">
              <ListItemText className={classes.link} primary={"Impressum"} />
            </ListItem>
          </List>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withRouter(Menu));
