import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import RPG from "./lib/RPG";
import { ThemeProvider } from "@material-ui/core/styles";
import { darkTheme } from "./style/theme";
import { CssBaseline } from "@material-ui/core";
import MUICookieConsent from "material-ui-cookie-consent";

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <MUICookieConsent
        cookieName="mySiteCookieConsent"
        componentType="Dialog" // default value is Snackbar
        message="This site uses cookies.... bla bla..."
      />
      <Router>
        <RPG />
      </Router>
    </ThemeProvider>
  );
}
export default App;
